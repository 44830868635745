import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Steps, Form, Input, Button, Row, Col, message } from "antd";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import GuestLayout from "../layout/GuestLayout";
import { submitUserDetails, sendOtp, verifyOtp } from "../services/authService"; // Assume these services exist

const { Step } = Steps;

const UserInfoForm = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [otpSent, setOtpSent] = useState(false);

  const handleNext = async () => {
    try {
      await form.validateFields();
      const values = await form.getFieldsValue();
      console.log("Current form values:", values);

      if (currentStep === 1 && !otpSent) {
        const phone = values.phone;
        console.log("Sending OTP to phone:", phone);
        await sendOtp(phone);
        setOtpSent(true);
        message.success("OTP sent to your phone!");
      }
      setCurrentStep(currentStep + 1);
    } catch (errorInfo) {
      console.log("Validation Failed:", errorInfo);
    }
  };

  const handlePrev = () => {
    setCurrentStep(currentStep - 1);
  };

  const onFinish = async () => {
    try {
      const otp = form.getFieldValue("otp");
      const phone = form.getFieldValue("phone");
      const orgName = form.getFieldValue("orgName");
      const name = form.getFieldValue("name");

      console.log("Verifying OTP:", otp, "for phone:", phone);

      const isOtpValid = await verifyOtp(phone, otp);

      if (isOtpValid) {
        const userData = {
          orgName,
          name,
          phone,
        };

        const data = await submitUserDetails(userData);
        console.log("Success:", data);
        message.success("Form submitted successfully!");
        navigate("/dashboard");
      } else {
        message.error("Invalid OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      message.error("Failed to submit the form. Please try again.");
    }
  };

  return (
    <GuestLayout>
      <Row justify="center" align="middle" style={{ minHeight: "70vh" }}>
        <Col xs={24} sm={20} md={16} lg={12} xl={8}>
          <div style={{ margin: "0 auto" }}>
            <Steps current={currentStep} style={{ marginBottom: "30px" }}>
              <Step title="Organization Info" />
              <Step title="User Info" />
              <Step title="OTP Verification" />
            </Steps>
            <Form
              form={form}
              name="threeStepForm"
              onFinish={onFinish}
              initialValues={{ orgName: "", name: "", phone: "", otp: "" }}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}>
              {currentStep === 0 && (
                <Form.Item
                  name="orgName"
                  label="Organization Name"
                  rules={[
                    {
                      required: true,
                      message: "Please input organization name!",
                    },
                  ]}>
                  <Input />
                </Form.Item>
              )}
              {currentStep === 1 && (
                <>
                  <Form.Item
                    name="name"
                    label="Name"
                    rules={[
                      { required: true, message: "Please input your name!" },
                    ]}>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="phone"
                    label="Phone"
                    rules={[
                      {
                        required: true,
                        message: "Please input your phone number!",
                      },
                    ]}>
                    <PhoneInput
                      country={"us"}
                      value={form.getFieldValue("phone")}
                      onChange={(phone) => form.setFieldsValue({ phone })}
                      inputStyle={{ width: "100%" }}
                    />
                  </Form.Item>
                </>
              )}
              {currentStep === 2 && (
                <Form.Item
                  name="otp"
                  label="OTP"
                  rules={[
                    {
                      required: true,
                      message: "Please input the OTP sent to your phone!",
                    },
                    {
                      len: 6,
                      message: "OTP should be 6 digits!",
                    },
                  ]}>
                  <Input />
                </Form.Item>
              )}
              <Form.Item style={{ textAlign: "center" }}>
                {currentStep > 0 && (
                  <Button style={{ marginRight: 8 }} onClick={handlePrev}>
                    Previous
                  </Button>
                )}
                {currentStep < 2 && (
                  <Button type="primary" onClick={handleNext}>
                    Next
                  </Button>
                )}
                {currentStep === 2 && (
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                )}
              </Form.Item>
            </Form>
          </div>
        </Col>
      </Row>
    </GuestLayout>
  );
};

export default UserInfoForm;
