import React, { useEffect, useState } from "react";
import { Spin, Table, message } from "antd";
import { getAllUsers } from "../../services/adminService";

const UserList = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getAllUsers();
        setUsers(data);
      } catch (error) {
        setError(error);
        message.error("Failed to load users");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  if (loading) return <Spin size="large" />;
  if (error) return <p>Error: {error.message}</p>;

  const openProfileInNewTab = (organizationId) => {
    const token = sessionStorage.getItem("token");
    const url = `/user-profile?token=${encodeURIComponent(token)}&organizationId=${encodeURIComponent(organizationId)}`;
    const windowFeatures = "incognito";
  
    window.open(url, "_blank", windowFeatures);
  };

  const columns = [
    {
      title: "User ID",
      dataIndex: "instagramUserName",
      key: "instagramUserName",
      render: (text, record) => (
        <a href="#" onClick={() => openProfileInNewTab(record.organizationId)}>
          {text}
        </a>
      ),
    },
    {
      title: "Organization ID",
      dataIndex: "organizationId",
      key: "organizationId",
    },
    {
      title: "Contact Person",
      dataIndex: "contactPerson",
      key: "contactPerson",
    },
    {
      title: "Phone Number",
      dataIndex: "contactPhoneNumber",
      key: "contactPhoneNumber",
    },
    {
      title: "Prompt",
      dataIndex: "prompt",
      key: "prompt",
    },
    {
      title: "Last Login",
      dataIndex: "lastLogin",
      key: "lastLogin",
    },
  ];

  return (
    <div>
      <h2>List of Users</h2>
      <Table dataSource={users} columns={columns} rowKey="organizationId" />
    </div>
  );
};

export default UserList;