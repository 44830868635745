import React, { useEffect } from "react";
import { Form, Input, Button, Typography, Space, message } from "antd";
import {
  getResponseTemplate,
  submitResponseTemplate,
} from "../../services/userService";
import { createNotification } from "../../services/conversationService";

const { TextArea } = Input;
const { Title } = Typography;

const ResponseTemplate = ({ refreshNotifications }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    const fetchTemplateData = async () => {
      try {
        const organizationId = sessionStorage.getItem("organizationId");
        const responseData = await getResponseTemplate(organizationId);
        form.setFieldsValue({
          aboutUs: responseData.about,
          specialInteraction: responseData.specialInteraction,
          shoppingPolicy: responseData.shoppingPolicy,
          paymentTerm: responseData.paymentTerm,
          paymentType: responseData.paymentType,
        });
      } catch (error) {
        message.error("Failed to load response template data");
      }
    };

    fetchTemplateData();
  }, [form]);

  const onFinish = async (values) => {
    const requestData = {
      about: values.aboutUs,
      specialInteraction: values.specialInteraction,
      shoppingPolicy: values.shoppingPolicy,
      paymentTerm: values.paymentTerm,
      paymentType: values.paymentType,
    };

    try {
      const response = await submitResponseTemplate(requestData);
      message.success("Response Template Update Successfully");

      // Create notification
      try {
        const notificationMessage = "Response Template has been updated.";
        await createNotification(notificationMessage);
        refreshNotifications();
      } catch (notificationError) {
        console.error("Notification Error:", notificationError);
      }
    } catch (error) {
      console.error("Error:", error);
      message.error("Failed to update response template");
    }
  };

  const onReset = () => {
    form.resetFields();
  };

  return (
    <div>
      <Title level={2} style={{ textAlign: "center", marginBottom: "20px" }}>
        Response Template
      </Title>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          aboutUs: "",
          specialInteraction: "",
          shoppingPolicy: "",
          paymentTerm: "",
          paymentType: "",
        }}>
        <Form.Item
          label="About Us"
          name="aboutUs"
          rules={[
            { required: true, message: "Please input the About Us section!" },
          ]}>
          <TextArea rows={4} />
        </Form.Item>

        <Form.Item
          label="Special Interaction"
          name="specialInteraction"
          rules={[
            {
              required: true,
              message: "Please input the Special Interaction section!",
            },
          ]}>
          <TextArea rows={4} />
        </Form.Item>

        <Form.Item
          label="Shopping Policy"
          name="shoppingPolicy"
          rules={[
            {
              required: true,
              message: "Please input the Shopping Policy section!",
            },
          ]}>
          <TextArea rows={4} />
        </Form.Item>

        <Form.Item
          label="Payment Term"
          name="paymentTerm"
          rules={[
            {
              required: true,
              message: "Please input the Payment Term section!",
            },
          ]}>
          <TextArea rows={2} />
        </Form.Item>

        <Form.Item
          label="Payment Type"
          name="paymentType"
          rules={[
            { required: true, message: "Please input the Payment Type!" },
          ]}>
          <Input />
        </Form.Item>

        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
            <Button htmlType="button" onClick={onReset}>
              Cancel
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ResponseTemplate;
