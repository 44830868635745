// src/App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import UserLogin from "./pages/UserLogin";
import UserBasicInfo from "./pages/UserBasicInfo";
import UserDashboard from "./pages/UserDashboard";
import MVAdminUserDashboard from "./pages/MVAdminUserDashboard";
import FacebookCallback from "./components/facebook/FacebookCallback";
import Login from "./pages/Login";
import PrivateRoute from "./components/PrivateRoute"; // Adjust the path as needed

const App = () => (
  <Router>
    <Routes>
      <Route path="/" element={<UserLogin />} />
      <Route path="/fb/callback" element={<FacebookCallback />} />
      <Route
        path="/user_info"
        element={<PrivateRoute element={UserBasicInfo} />}
      />
      <Route
        path="/dashboard"
        element={<PrivateRoute element={UserDashboard} />}
      />
      <Route
        path="/user-profile"
        element={<PrivateRoute element={MVAdminUserDashboard} />}
      />
      <Route path="/login" element={<Login />} />
    </Routes>
  </Router>
);

export default App;
