// AddProductModal.js
import React, { useEffect } from "react";
import { Modal, Form, Input, InputNumber, Button, message, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { addProduct, updateProduct } from "../../services/productService";
import { createNotification } from "../../services/conversationService";

const AddProductModal = ({
  visible,
  onClose,
  onAddProduct,
  initialValues,
  refreshNotifications,
}) => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = React.useState([]);

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
      // If initialValues contains image data, set it in fileList
      if (initialValues.image) {
        setFileList([
          {
            uid: "-1",
            name: "initial_image",
            status: "done",
            url: initialValues.image,
          },
        ]);
      }
    }
  }, [initialValues, form]);

  const handleAddProduct = async (values) => {
    try {
      let newProduct;
      let imageBase64 = null;

      if (fileList.length > 0) {
        const file = fileList[0].originFileObj;
        imageBase64 = await getBase64(file);
      }

      const productData = {
        ...values,
        image: imageBase64,
      };

      if (initialValues) {
        productData.productId = initialValues.id;
        newProduct = await updateProduct(productData);
      } else {
        newProduct = await addProduct(productData);
      }

      // Create notification
      try {
        const notificationMessage = `Product ${
          initialValues ? "updated" : "added"
        } successfully!`;
        await createNotification(notificationMessage);
        refreshNotifications(); // Refresh notifications here
      } catch (notificationError) {
        console.error("Notification Error:", notificationError);
      }

      message.success(
        `Product ${initialValues ? "updated" : "added"} successfully!`
      );
      form.resetFields();
      setFileList([]);
      onClose();
    } catch (error) {
      message.error(`Failed to ${initialValues ? "update" : "add"} product`);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setFileList([]);
    onClose();
  };

  const handleFileChange = ({ fileList }) => setFileList(fileList);

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  return (
    <Modal
      title={initialValues ? "Edit Product" : "Add Product"}
      visible={visible}
      onCancel={handleCancel}
      footer={null}>
      <Form
        form={form}
        onFinish={handleAddProduct}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        layout="vertical">
        <Form.Item
          name="productName"
          label="Product Name"
          initialValue={initialValues ? initialValues.name : ""}
          rules={[
            { required: true, message: "Please input the product name!" },
          ]}>
          <Input placeholder="Product Name" />
        </Form.Item>
        <Form.Item
          name="price"
          label="Price"
          initialValue={initialValues ? initialValues.price : ""}
          rules={[
            { required: true, message: "Please input the product price!" },
          ]}>
          <InputNumber
            min={0}
            formatter={(value) => `₹ ${value}`}
            style={{ width: "100%" }}
            placeholder="Price"
          />
        </Form.Item>
        <Form.Item
          name="buyingLink"
          label="Buying Link"
          initialValue={initialValues ? initialValues.buying_link : ""}
          rules={[
            { required: true, message: "Please input the buying link!" },
          ]}>
          <Input placeholder="Buying Link" />
        </Form.Item>
        <Form.Item
          name="paymentLink"
          label="Payment Link"
          initialValue={initialValues ? initialValues.payment_link : ""}
          rules={[
            { required: true, message: "Please input the payment link!" },
          ]}>
          <Input placeholder="Payment Link" />
        </Form.Item>
        <Form.Item
          name="shippingPolicy"
          label="Shipping Policy"
          initialValue={initialValues ? initialValues.shipping_policy : ""}
          rules={[
            { required: true, message: "Please input the shipping policy!" },
          ]}>
          <Input.TextArea rows={2} placeholder="Shipping Policy" />
        </Form.Item>
        <Form.Item
          name="productDescription"
          label="Product Description"
          initialValue={initialValues ? initialValues.description : ""}>
          <Input.TextArea rows={4} placeholder="Product Description" />
        </Form.Item>
        <Form.Item label="Product Image">
          <Upload
            listType="picture"
            fileList={fileList}
            onChange={handleFileChange}
            beforeUpload={() => false}
            maxCount={1}>
            <Button icon={<UploadOutlined />}>Upload (Max: 1)</Button>
          </Upload>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{ width: "49%", marginRight: "2%" }}>
            {initialValues ? "Update Product" : "Add Product"}
          </Button>
          <Button
            type="default"
            onClick={handleCancel}
            style={{ width: "49%" }}>
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddProductModal;
