import React, { useState, useEffect } from "react";
import { Spin, Table, message, Button, Typography, Modal } from "antd";
import { conversationList } from "../../services/conversationService";
const { Title } = Typography;

const ConversationTable = () => {
  const [data, setData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState([]);

  const fetchData = async () => {
    const conversationData = await conversationList();
    setData(conversationData);
  };

  const showModal = (messages) => {
    setModalContent(messages);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const columns = [
    // {
    //   title: "Context",
    //   dataIndex: "context",
    //   key: "context",
    // },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Instagram Name",
      dataIndex: "messages",
      key: "instagram_name",
      render: (messages) => messages[0]?.instagram_userName || "N/A",
    },
    // {
    //   title: "Products",
    //   dataIndex: "messages",
    //   key: "products",
    //   render: (messages) => messages[0]?.products.join(", ") || "N/A",
    // },
    {
      title: "Action",
      key: "action",
      render: (record) => (
        <Button onClick={() => showModal(record.messages)}>
          View Messages
        </Button>
      ),
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <Title level={4} style={{ textAlign: "center", marginBottom: "20px" }}>
        Conversation
      </Title>
      <Table columns={columns} dataSource={data} rowKey="_id" />
      <Modal
        title="Messages"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {modalContent.map((message) => (
          <p key={message._id}>
            <b>{message.instagram_userName || "Unknown"}:</b> {message.message}
          </p>
        ))}
      </Modal>
    </div>
  );
};

export default ConversationTable;