import React, { useEffect, useState } from "react";
import { Spin, Table, message, Button, Modal, Form, Select } from "antd";
import { getPostDetails, refreshPost } from "../../services/userService";
import {
  getProductDetails,
  saveTagMedia,
  getProductName,
} from "../../services/productService";
import { createNotification } from "../../services/conversationService";

const { Option } = Select;

const PostList = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [posts, setPosts] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [taggingInfo, setTaggingInfo] = useState({
    productId: "",
  });

  const fetchData = async () => {
    try {
      setLoading(true);
      const organizationId = sessionStorage.getItem("organizationId");
      const postDetails = await getPostDetails(organizationId);
      const productDetails = await getProductDetails();

      const postsWithProductNames = await Promise.all(
        postDetails.map(async (post) => {
          if (post.tagProductId) {
            const productName = await getProductName(post.tagProductId);
            return { ...post, productName: productName.productName };
          }
          return { ...post, productName: "No Product" };
        })
      );

      setPosts(postsWithProductNames);
      setProducts(productDetails);

      try {
        const notificationMessage = "Media Reload Successfully";
        await createNotification(notificationMessage);
      } catch (notificationError) {
        console.error("Notification Error:", notificationError);
      }
    } catch (error) {
      setError(error);
      message.error("Failed to load data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onSelectChange = (selectedKeys) => {
    setSelectedRowKeys(selectedKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleTagMedia = (postId = null) => {
    if (postId && selectedRowKeys.length === 0) {
      setSelectedRowKeys([postId]);
    }
    setModalVisible(true);
  };

  const handleTagging = async () => {
    if (!taggingInfo.productId) {
      message.warning("Please select a product to tag.");
      return;
    }

    try {
      const organizationId = sessionStorage.getItem("organizationId");
      const tagRequests = selectedRowKeys.map(postId => ({
        postId,
        productId: taggingInfo.productId
      }));

      await saveTagMedia({
        organizationId,
        tagRequests
      });

      try {
        const notificationMessage = `Tagged ${selectedRowKeys.length} media with product ID: ${taggingInfo.productId}`;
        await createNotification(notificationMessage);
      } catch (notificationError) {
        console.error("Notification Error:", notificationError);
      }

      message.success(`Tagged ${selectedRowKeys.length} media successfully`);
      setModalVisible(false);
      fetchData(); // Reload the data
      setSelectedRowKeys([]);
    } catch (error) {
      console.error("Error tagging media:", error);
      message.error("Failed to tag media");
    }
  };

  if (loading) return <Spin size="large" />;
  if (error) return <p>Error: {error.message}</p>;

  const columns = [
    // {
    //   title: "ID",
    //   dataIndex: "id",
    //   key: "id",
    // },
    // {
    //   title: "Media Type",
    //   dataIndex: "media_type",
    //   key: "media_type",
    // },
    {
      title: "Media",
      dataIndex: "media_url",
      key: "media_url",
      render: (text, record) => {
         if (record?.media_type === "VIDEO") {
          return (
            <img src={record.thumbnail_url} alt="Media" style={{ width: 100 }} />
          );
        } else if (record?.media_type) {
          return (
            <img src={record.media_url} alt="Media" style={{ width: 100 }} />
          );
        } else {
          return <span>Unsupported media type</span>;
        }
      },
    },
    {
      title: "Caption",
      dataIndex: "caption",
      key: "caption",
    },
    {
      title: "Product Name",
      dataIndex: "productName",
      key: "productName",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Button
          type="link"
          onClick={() => handleTagMedia(record.id)}
        >
          Tag Product
        </Button>
      ),
    },
  ];

  return (
    <div>
      <div style={{
        display: "flex",
        justifyContent: "space-between",
        marginBottom: 16,
      }}>
        <h2>List of Media</h2>
        <Button type="primary" onClick={fetchData}>
          Reload
        </Button>
      </div>
      <Table
        rowSelection={rowSelection}
        dataSource={posts}
        columns={columns}
        rowKey="id"
      />
      <Modal
        title="Tag Product"
        visible={modalVisible}
        onOk={handleTagging}
        onCancel={() => setModalVisible(false)}
      >
        <Form layout="vertical">
          <Form.Item label="Select Product">
            <Select
              value={taggingInfo.productId}
              onChange={(value) => setTaggingInfo({ ...taggingInfo, productId: value })}
            >
              {products.map((product) => (
                <Option key={product.id} value={product.id}>
                  {product.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default PostList;