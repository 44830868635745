import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input, Button, Alert, Row, Col, Typography } from "antd";
import { login } from "../services/authService";
import GuestLayout from "../layout/GuestLayout";

const { Title } = Typography;

const Login = () => {
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const onFinish = async (values) => {
    try {
      const { token, organizationId, otherUserId } = await login(
        values.username,
        values.password
      );
  
      if (token) {
        sessionStorage.setItem("token", token);
      }
  
      if (organizationId) {
        sessionStorage.setItem("organizationId", organizationId);
      }
  
      if (otherUserId) {
        sessionStorage.setItem("otherUserId", otherUserId);
      }
  
      navigate("/dashboard");
    } catch (err) {
      setError("Login failed. Please check your credentials.");
    }
  };

  const handleLogin = () => {
    form.validateFields().then((values) => {
      onFinish(values);
    }).catch((errorInfo) => {
      // Handle form validation errors
      console.log("Form validation failed:", errorInfo);
    });
  };

  const [form] = Form.useForm();

  return (
    <GuestLayout>
      <Row justify="center" align="middle" style={{ height: "100%" }}>
        <Col xs={22} sm={18} md={12} lg={8}>
          <Form
            form={form}
            layout="vertical"
            style={{
              padding: "30px",
              backgroundColor: "#fff",
              borderRadius: "5px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Title level={3} style={{ textAlign: "center", marginBottom: "30px" }}>
              Login
            </Title>
            {error && <Alert message={error} type="error" showIcon />}
            <Form.Item
              name="username"
              rules={[{ required: true, message: "Please input your username!" }]}
            >
              <Input placeholder="Username" size="large" />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: "Please input your password!" }]}
            >
              <Input.Password placeholder="Password" size="large" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" size="large" block onClick={handleLogin}>
                Login
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </GuestLayout>
  );
};

export default Login;