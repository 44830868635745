import axios from "axios";

// Function to retrieve the organizationId from local storage
const getOrganizationId = () => {
  const organizationId = sessionStorage.getItem("organizationId");
  if (!organizationId) {
    throw new Error("Organization ID not found in local storage");
  }
  return organizationId;
};

// Function to retrieve the token from local storage
const getToken = () => {
  const token = sessionStorage.getItem("token");
  if (!token) {
    throw new Error("Token not found in local storage");
  }
  return token;
};

export const createNotification = async (message) => {
  const organizationId = getOrganizationId();
  const token = getToken();
  const response = await axios.post(
    `${process.env.REACT_APP_CONVERSATION_BASE_URL}/conversation/create-notification`,
    { message, organizationId },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

export const notificationList = async () => {
  const organizationId = getOrganizationId();
  const token = getToken();
  const response = await axios.get(
    `${process.env.REACT_APP_CONVERSATION_BASE_URL}/conversation/notification-list?organizationId=${organizationId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  console.log("notification", response);
  return response.data;
};

export const conversationList = async () => {
  const organizationId = getOrganizationId();
  const token = getToken();
  const response = await axios.get(
    `${process.env.REACT_APP_CONVERSATION_BASE_URL}/conversation/get-conversion?organizationId=${organizationId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

export const markNotificationAsRead = async (notificationId) => {
  const token = getToken();
  await axios.post(
    `${process.env.REACT_APP_CONVERSATION_BASE_URL}/conversation/mark-as-read`,
    { notificationId },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
