import React from "react";
import { Row, Col, Typography } from "antd";
import FacebookLogin from "../components/facebook/FacebookLogin";
import GuestLayout from "../layout/GuestLayout";
const { Title } = Typography;
const LoginPage = () => {
  return (
    <GuestLayout>
      <Row justify="center" align="middle" style={{ minHeight: "70vh" }}>
        <Col>
          <Title level={3}>Login to your account</Title>
          <FacebookLogin />
        </Col>
      </Row>
    </GuestLayout>
  );
};
export default LoginPage;
