import React, { useState, useEffect } from 'react';
import { Table, Spin, notification } from 'antd';
import { getAllSupportRequests } from '../../services/supportService';
import MVAdminChatModal from './MVAdminChatModal';

const MVAdminSupportSection = () => {
  const [allSupportRequests, setAllSupportRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRequestId, setSelectedRequestId] = useState(null);
  const [selectedOrganizationId, setSelectedOrganizationId] = useState(null);
  const [showChatModal, setShowChatModal] = useState(false);

  useEffect(() => {
    const otherUserId = sessionStorage.getItem('otherUserId');
    fetchAllSupportRequests(otherUserId);
  }, []);

  const fetchAllSupportRequests = async () => {
    try {
      setLoading(true);
      const otherUserId = sessionStorage.getItem('otherUserId');
      const token = sessionStorage.getItem('token');
      const requests = await getAllSupportRequests(otherUserId, token);
      const flattenedRequests = requests.flatMap(org => org.supportRequests.map(request => ({
        ...request,
        organizationId: org.organizationId,
        instagramUserName: org.instagramUserName,
      })));
      const requestsWithKeys = flattenedRequests.map((request, index) => ({
        ...request,
        key: index.toString(),
      }));
      const sortedRequests = requestsWithKeys.sort((a, b) => {
        if (a.status === 'Resolved' && b.status !== 'Resolved') {
          return 1;
        } else if (a.status !== 'Resolved' && b.status === 'Resolved') {
          return -1;
        } else {
          return new Date(a.createdAt) - new Date(b.createdAt);
        }
      });
      setAllSupportRequests(sortedRequests);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching all support requests:', error);
      setLoading(false);
    }
  };

  const handleChatModalClose = () => {
    setShowChatModal(false);
    fetchAllSupportRequests();
  };

  const handleInstagramUserNameClick = (requestId, organizationId) => {
    const selectedRequest = allSupportRequests.find((request) => request.requestId === requestId);
    if (selectedRequest.status === 'Resolved') {
      notification.warning({
        message: 'Request Already Resolved',
        description: 'The selected support request has already been resolved.',
      });
    }
    setSelectedRequestId(requestId);
    setSelectedOrganizationId(organizationId);
    setShowChatModal(true);
  };

  const columns = [
    { 
      title: 'User Id', 
      dataIndex: 'instagramUserName', 
      key: 'instagramUserName', 
      render: (text, record) => <a onClick={() => handleInstagramUserNameClick(record.requestId, record.organizationId)}>{text}</a> 
    },
    { title: 'Organization ID', dataIndex: 'organizationId', key: 'organizationId' },
    { title: 'Request ID', dataIndex: 'requestId', key: 'requestId' },
    { title: 'Type', dataIndex: 'type', key: 'type' },
    { title: 'Query', dataIndex: 'query', key: 'query' },
    { title: 'Request Created Date', dataIndex: 'createdAt', key: 'createdAt' },
    { title: 'Status', dataIndex: 'status', key: 'status' },
  ];

  return (
    <div>
      {loading ? (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <Spin size="large" />
        </div>
      ) : (
        <Table
          dataSource={allSupportRequests}
          columns={columns}
          pagination={false}
        />
      )}
      <MVAdminChatModal
        visible={showChatModal}
        onClose={handleChatModalClose}
        requestId={selectedRequestId}
        organizationId={selectedOrganizationId}
        isResolved={allSupportRequests.find(q => q.requestId === selectedRequestId)?.status === 'Resolved'}
      />
    </div>
  );
};

export default MVAdminSupportSection;