import React, { useEffect, useState } from "react";
import { Spin, Table, message, Button, Typography, Modal, Checkbox, List } from "antd";
import {
  getProductDetails,
  addProduct,
  deleteProduct,
  updateProduct,
  mediaCount,
  saveTagMedia,
} from "../../services/productService";
import { getPostDetails } from "../../services/userService";
import AddProductModal from "./AddProductModal";
import { EditOutlined, DeleteOutlined, TagOutlined } from "@ant-design/icons";

const { Title } = Typography;

const ProductList = ({ refreshNotifications }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [products, setProducts] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingProduct, setEditingProduct] = useState(null);
  const [isTagModalVisible, setIsTagModalVisible] = useState(false);
  const [allMedia, setAllMedia] = useState([]);
  const [selectedMedia, setSelectedMedia] = useState([]);
  const [taggingProductId, setTaggingProductId] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const fetchData = async () => {
    try {
      setLoading(true);
      const data = await getProductDetails();
      const productsWithMediaCount = await Promise.all(
        data.map(async (product) => {
          const mediaCountValue = await mediaCount(product.id);
          return { ...product, mediaCount: mediaCountValue.mediaCount };
        })
      );
      setProducts(productsWithMediaCount);

      const organizationId = sessionStorage.getItem("organizationId");
      const mediaData = await getPostDetails(organizationId);
      setAllMedia(mediaData);
    } catch (error) {
      setError(error);
      message.error("Failed to load data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleAddProduct = async (newProduct) => {
    if (editingProduct) {
      try {
        await updateProduct({
          ...newProduct,
          productId: editingProduct.id,
        });
        message.success("Product updated successfully");
        setEditingProduct(null);
        fetchData();
      } catch (error) {
        message.error("Failed to update product");
      }
    } else {
      try {
        await addProduct(newProduct);
        message.success("Product added successfully");
        fetchData();
      } catch (error) {
        message.error("Failed to add product");
      }
    }
    setIsModalVisible(false);
    refreshNotifications();
  };

  const handleEdit = (record) => {
    setEditingProduct(record);
    setIsModalVisible(true);
  };

  const handleDelete = async (record) => {
    try {
      await deleteProduct(record.id);
      message.success("Product deleted successfully");
      fetchData();
    } catch (error) {
      message.error("Failed to delete product");
    }
  };

  const handleTagMedia = (productId) => {
    setTaggingProductId(productId);
    setSelectedMedia([]);
    setIsTagModalVisible(true);
  };

  const handleTagMediaSubmit = async () => {
    try {
      const organizationId = sessionStorage.getItem("organizationId");
      await saveTagMedia({
        organizationId,
        tagRequests: selectedMedia.map(mediaId => ({
          postId: mediaId,
          productId: taggingProductId
        }))
      });
      message.success("Media tagged successfully");
      setIsTagModalVisible(false);
      fetchData();
      // refreshNotifications();
    } catch (error) {
      console.error("Error tagging media:", error);
      message.error("Failed to tag media");
    }
  };

  const renderMedia = (record) => {
    if (record.media_type === "VIDEO") {
      return (
        <img src={record.thumbnail_url} alt="Media" style={{ width: 100 }} />
      );
    } else if (record.media_type) {
      return (
        <img src={record.media_url} alt="Media" style={{ width: 100 }} />
      );
    } else {
      return <span>Unsupported media type</span>;
    }
  };

  if (loading) return <Spin size="large" />;
  if (error) return <p>Error: {error.message}</p>;

  const columns = [
    // {
    //   title: "Product ID",
    //   dataIndex: "id",
    //   key: "id",
    // },
    {
      title: "Product Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (price) => `₹${price}`,
    },
    {
      title: "Buying Link",
      dataIndex: "buying_link",
      key: "buying_link",
      render: (link) => (
        <a href={link} target="_blank" rel="noopener noreferrer">
          {link}
        </a>
      ),
    },
    {
      title: "Payment Link",
      dataIndex: "payment_link",
      key: "payment_link",
      render: (text) => <div style={{ whiteSpace: "pre-wrap" }}>{text}</div>,
    },
    {
      title: "Shipping Policy",
      dataIndex: "shipping_policy",
      key: "shipping_policy",
    },
    {
      title: "Media Count",
      dataIndex: "mediaCount",
      key: "mediaCount",
    },
    {
      title: "Image",
      dataIndex: "imageUrl",
      key: "imageUrl",
      render: (url) => (
        <img
          src={url}
          alt="product"
          loading="lazy"
          style={{ width: 100, height: 100 }}
        />
      ),
    },
    {
      title: "Tag",
      key: "tag",
      render: (text, record) => (
        <Button
          icon={<TagOutlined />}
          onClick={() => handleTagMedia(record.id)}
        >
          Tag Media
        </Button>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <span>
          <EditOutlined
            style={{ marginRight: 8 }}
            onClick={() => handleEdit(record)}
          />
          <DeleteOutlined
            onClick={() => handleDelete(record)}
          />
        </span>
      ),
    },
  ];

  return (
    <div>
      <Title level={4} style={{ textAlign: "center", marginBottom: "20px" }}>
        Product Listing
      </Title>
      <Button type="primary" onClick={() => setIsModalVisible(true)} style={{ marginBottom: 16 }}>
        Add Product
      </Button>
      <Table
        dataSource={products}
        columns={columns}
        rowKey="id"
      />
      <AddProductModal
        visible={isModalVisible}
        onClose={() => {
          setIsModalVisible(false);
          setEditingProduct(null);
          fetchData();
        }}
        onAddProduct={handleAddProduct}
        initialValues={editingProduct}
        refreshNotifications={refreshNotifications}
      />
      <Modal
        title="Tag Media"
        visible={isTagModalVisible}
        onCancel={() => setIsTagModalVisible(false)}
        onOk={handleTagMediaSubmit}
        width={800}
      >
        <List
          dataSource={allMedia.slice((currentPage - 1) * pageSize, currentPage * pageSize)}
          renderItem={item => (
            <List.Item>
              <Checkbox
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedMedia([...selectedMedia, item.id]);
                  } else {
                    setSelectedMedia(selectedMedia.filter(id => id !== item.id));
                  }
                }}
                checked={selectedMedia.includes(item.id)}
              >
                <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                  <div style={{ marginRight: '16px' }}>
                    {renderMedia(item)}
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{
                      maxWidth: '450px',
                      wordWrap: 'break-word',
                      overflowWrap: 'break-word'
                    }}>
                      {item.caption ? item.caption : 'No caption'}
                    </div>
                  </div>
                </div>
              </Checkbox>
            </List.Item>
          )}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            total: allMedia.length,
            onChange: (page, pageSize) => {
              setCurrentPage(page);
              setPageSize(pageSize);
            },
            onShowSizeChange: (current, size) => {
              setPageSize(size);
              setCurrentPage(1);
            },
            showSizeChanger: true,
            pageSizeOptions: ['10', '20'],
          }}
          style={{ maxHeight: '400px', overflowY: 'auto' }}
        />
      </Modal>
    </div>
  );
};

export default ProductList;