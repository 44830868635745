// src/components/UserInfo.js
import React, { useEffect, useState } from "react";
import { Card, Col, Row, Avatar, Statistic, Spin } from "antd";
import { getUserProfile } from "../../services/userService";
import ToggleSubscription from "./ToggleSubscription";

const UserInfo = () => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const organizationId = sessionStorage.getItem("organizationId");
        const data = await getUserProfile(organizationId);
        setUserData(data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  if (loading) {
    return <Spin size="large" />;
  }

  if (!userData) {
    return <div>Error loading user data</div>;
  }

  return (
    <Card size="small" style={{ minHeight: "fit-content" }}>
      <Row gutter={[8, 8]} align="middle">
        <Col span={4}>
          <Avatar size={100} src={userData.avatar} />
        </Col>
        <Col span={20}>
          <h2 style={{ margin: "4px 0", fontSize: "20px" }}>{userData.name}</h2>
          <p style={{ margin: "4px 0", fontSize: "16px" }}>{userData.title}</p>
          <Row gutter={[8, 8]}>
            <Col span={8}>
              <Statistic title="Media" value={userData.mediaCount} />
            </Col>
            <Col span={8}>
              <Statistic title="Followers" value={userData.followers} />
            </Col>
            <Col span={8}>
              <Statistic title="Products" value={userData.productCount} />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <ToggleSubscription />
        </Col>
      </Row>
    </Card>
  );
};

export default UserInfo;
