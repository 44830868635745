// src/components/ToggleSubscription.js
import React, { useEffect, useState } from 'react';
import { Switch, message, Spin } from 'antd';
import axios from 'axios';

const SubscriptionToggle = () => {
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [loading, setLoading] = useState(true);

  const getToken = () => sessionStorage.getItem("token");
  const getOrganizationId = () => sessionStorage.getItem("organizationId");

  const fetchSubscriptionStatus = async () => {
    try {
      const token = getToken();
      const organizationId = getOrganizationId();
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/users/subscribe/${organizationId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setIsSubscribed(response.data.isSubscriptionEnabled);
    } catch (error) {
      console.error('Error fetching subscription status:', error);
      message.error('Failed to fetch subscription status');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSubscriptionStatus();
  }, []);

  const handleToggle = async (checked) => {
    setLoading(true);
    try {
      const token = getToken();
      const organizationId = getOrganizationId();
      const method = checked ? 'post' : 'delete';
      
      await axios({
        method,
        url: `${process.env.REACT_APP_API_BASE_URL}/users/subscribe/${organizationId}`,
        headers: { Authorization: `Bearer ${token}` },
      });

      message.success(`Successfully ${checked ? 'subscribed' : 'unsubscribed'}`);
      await fetchSubscriptionStatus();
    } catch (error) {
      console.error('Error toggling subscription:', error);
      message.error('Failed to toggle subscription');
      setIsSubscribed(!checked); // Revert the toggle if the API call fails
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Spin />;
  }

  return (
    <div>
      <span style={{ marginRight: '10px' }}>Subscription Status:</span>
      <Switch
        checked={isSubscribed}
        onChange={handleToggle}
        loading={loading}
      />
    </div>
  );
};

export default SubscriptionToggle;