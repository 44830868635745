import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getAccessToken } from "../../services/authService";
import Loading from "../Loading";

const FacebookCallback = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get("code");
    if (code) {
      getAccessToken(code)
        .then((data) => {
          if (data.organizationId) {
            if (data.isUserExist) {
              sessionStorage.setItem("organizationId", data.organizationId);
              sessionStorage.setItem("token", data.token);
              navigate("/dashboard");
            } else {
              // Save organizationId in local storage
            sessionStorage.setItem("organizationId", data.organizationId);
            sessionStorage.setItem("token", data.token);
            // navigate("/user_info");
            navigate("/dashboard");
            }
          }
        })
        .catch((error) => {
          console.error("Error getting access token:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false); // Handle the case where there is no code in the URL
    }
  }, [location, navigate]);
  return <div>{loading && <Loading />}</div>;
};
export default FacebookCallback;
