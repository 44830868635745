// UserDashboard.js
import React, { useState, useEffect } from "react";
import UserLayout from "../layout/UserLayout";
import GetPostList from "../components/user/GetPostList";
import ResponseTemplate from "../components/user/ResponseTemplate";
import ContentSettingForm from "../components/user/ContentSetting";
import ProductList from "../components/product/ProductList";
import UserList from "../components/admin/UserList";
import SupportSection from "../components/support/SupportSection";
import SettingForm from "../components/user/SettingForm";
import ConversationTable from "../components/user/ConversationList";
import { useNavigate } from "react-router-dom";

import { roles, menuPermissions, getUserRole } from "../services/roleService";
import MVAdminSupportSection from "../components/support/MVAdminSupportSection";
import UniversalContext from "../components/aiadmin/UniversalContext";
import AIAdmin from "../components/aiadmin/AiAdmin";
import AccountDetails from "./AccountDetails";

const UserProfilePage = () => {
  const [selectedMenuKey, setSelectedMenuKey] = useState("0");
  const navigate = useNavigate();
  const [userRole, setUserRole] = useState(roles.UNAUTHORIZED);

  const handleMenuSelect = ({ key }) => {
    setSelectedMenuKey(key);
  };

  useEffect(() => {
    const fetchUserRole = async () => {
      const otherUserId = sessionStorage.getItem("otherUserId");
      const organizationId = sessionStorage.getItem("organizationId");
      const token = sessionStorage.getItem("token");

      if (token) {
        try {
          const role = await getUserRole(otherUserId, organizationId, token);
          setUserRole(role);
          if (role === roles.MVADMIN) {
            setSelectedMenuKey("12"); // Set Universal Context as default for MVADMIN
          } else if (role === roles.ADMIN) {
            setSelectedMenuKey("1"); // Set Post List as default for ADMIN
          }
        } catch (error) {
          console.error("Error fetching user role:", error);
          setUserRole(roles.UNAUTHORIZED);
        }
      } else {
        setUserRole(roles.UNAUTHORIZED);
      }
    };

    fetchUserRole();
  }, []);

  const renderContent = (refreshNotifications) => {
    switch (userRole) {
      case roles.ADMIN:
        switch (selectedMenuKey) {
          case "1":
            return <GetPostList refreshNotifications={refreshNotifications} />;
          case "2":
            return (
              <ResponseTemplate refreshNotifications={refreshNotifications} />
            );
          case "3":
            return <ProductList refreshNotifications={refreshNotifications} />;
          case "4":
            return <UserList />;
          case "5":
            return (
              <SupportSection refreshNotifications={refreshNotifications} />
            );
          case "8":
            return <SettingForm refreshNotifications={refreshNotifications} />;
          case "9":
            return <ConversationTable />;
          case "13":
            return <AccountDetails />;
          default:
            return <GetPostList />;
        }
      case roles.MVADMIN:
        switch (selectedMenuKey) {
          case "4":
            return <UserList />;
          case "5":
            return <MVAdminSupportSection />;
          case "11":
            return <AIAdmin />;
          case "12":
            return <UniversalContext />;
          default:
            return <UniversalContext />;
        }
      case roles.UNAUTHORIZED:
        return null; // Or you can render a custom message or component
      default:
        return null;
    }
  };

  return (
    <UserLayout
      userRole={userRole}
      selectedMenuKey={selectedMenuKey}
      onMenuSelect={handleMenuSelect}>
      {({ refreshNotifications }) => (
        <div
          style={{
            padding: "24px",
            background: "#fff",
            minHeight: "360px",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
          }}>
          {renderContent(refreshNotifications)}
        </div>
      )}
    </UserLayout>
  );
};

export default UserProfilePage;
